export const refactor = (data: any, type: any) => {
  const newResponse = [];

  for (const prop in data) {
    if (data[prop].observabilityAppLinks) {
      for (const details in data[prop].observabilityAppLinks) {
        if (data[prop].observabilityAppLinks[details]?.purpose == type) {
          const newObj: any = {};
          newObj.name =
            data[prop].appName === 'Dashboard' ? 'grafana' : data[prop].appName;
          newObj.purpose = type === 'MonitorDashboard' ? 'dashboard' : 'agent';
          newObj.url = data[prop].observabilityAppLinks[details].appURL;
          newObj.username =
            data[prop].observabilityAppLinks[details].appUsername;
          newObj.password =
            data[prop].observabilityAppLinks[details].appPassword;
          newResponse.push(newObj);
        }
      }
    }
  }

  return newResponse;
};

export const modifiedData = (response: any) => {
  const modifiedResponse = response;
  for (const prop in modifiedResponse) {
    if (modifiedResponse[prop].outputMaps && modifiedResponse[prop].outputMaps[0].observabilityAppLinks) {
      modifiedResponse[prop].dashboard = refactor(
        modifiedResponse[prop].outputMaps,
        'MonitorDashboard',
      );

      modifiedResponse[prop].agent = refactor(
        modifiedResponse[prop].outputMaps,
        'Agent',
      );
    } else {
      modifiedResponse[prop].dashboard = modifiedResponse[
        prop
      ].outputMaps.filter((item: any) => item.purpose === 'dashboard');
      modifiedResponse[prop].agent = modifiedResponse[prop].outputMaps.filter(
        (item: any) => item.purpose === 'agent',
      );
    }
  }
  return modifiedResponse;
};

/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: '100%',
    height: 60,
    margin: 'auto',
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" className={classes.svg} x="0px" y="0px"
	 width="100%" viewBox="0 0 512 512" enable-background="new 0 0 512 512">
<path fill="#00a9a5" opacity="1.000000" stroke="none" 
	d="
M177.439331,317.511566 
	C162.608643,297.196198 156.271271,274.715576 157.786118,250.072021 
	C160.420624,207.213608 192.631851,169.780014 234.955078,160.107391 
	C284.871826,148.699341 334.978119,176.327545 351.957947,224.621735 
	C372.375519,282.693634 334.803436,346.860229 274.338287,356.030487 
	C235.059601,361.987518 202.603241,348.954529 177.439331,317.511566 
M276.859192,342.383423 
	C277.822144,342.120667 278.785034,341.857666 279.748077,341.595215 
	C323.192932,329.755280 350.702972,287.583282 343.503143,243.856537 
	C335.493225,195.210205 290.694031,165.986053 247.669678,171.069672 
	C207.049667,175.869186 172.083298,209.016373 170.572662,254.546875 
	C169.554993,285.219177 182.183502,310.299042 207.236450,328.337341 
	C227.897797,343.213684 251.091125,347.551697 276.859192,342.383423 
z"/>
<path fill="#00a9a5" opacity="1.000000" stroke="none" 
	d="
M281.421936,266.589050 
	C285.811249,271.765106 289.956512,276.684998 294.917053,282.572540 
	C298.494446,251.271774 299.105103,221.386276 285.945374,192.842682 
	C288.091400,191.776337 289.299408,192.932022 290.555908,193.557678 
	C316.588196,206.520355 333.021729,237.281174 329.114777,265.678864 
	C324.719147,297.628754 301.614044,322.623352 271.380524,328.134521 
	C232.233521,335.270477 195.691757,311.273682 187.156219,272.468658 
	C183.535385,256.007416 185.862122,240.096756 193.300385,224.895615 
	C195.118958,221.179108 197.553879,220.646881 201.346176,221.057007 
	C234.519592,224.644562 259.221863,243.039917 281.421936,266.589050 
z"/>
<path fill="#00a9a5" opacity="1.000000" stroke="none" 
	d="
M256.908264,221.117035 
	C241.878754,211.959595 226.056137,206.539780 208.755463,206.832733 
	C208.340668,204.219421 209.872040,203.473709 210.929764,202.586792 
	C222.061371,193.252518 234.887344,187.721695 249.265778,185.817230 
	C251.909561,185.467041 254.448853,185.554428 256.735657,187.594193 
	C271.821930,201.050812 279.026520,218.137527 280.872406,239.508850 
	C272.546539,232.518631 265.455750,226.120163 256.908264,221.117035 
z"/>
</svg>
  );
};

export default LogoIcon;
import React from 'react';
import { boxColor } from '../../../theme';

function Onboarding() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: '1rem',
        backgroundColor: boxColor,
        borderRadius: '0.25rem',
        color: 'black',
        fontSize: '0.875rem',
        textAlign: 'left',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.25rem',
        }}
      >
        <div
          style={{
            fontSize: '1rem',
            marginBottom: '0.25rem',
            fontWeight: 500,
          }}
        >
          Hello! I am Aiden, your DevOps copilot.
        </div>

        <div
          style={{
            fontSize: '1rem',
            fontWeight: 400,
          }}
        >
          Let’s get started by knowing a bit about your infrastructure. It will
          help me provide you specific answers with your infrastructure in the
          context. <br />
          <br />
          Tell me about your tools, services, git repos and anything you may
          want me to know. Its a free text field, there is no specific format.
          You willl be able to edit or update the details later.
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.25rem',
        }}
      >
        <div
          style={{
            fontSize: '1rem',
            marginBottom: '0.5rem',
            fontWeight: 500,
          }}
        >
          For example:
        </div>
        <div
          style={{
            fontWeight: 400,
          }}
        >
          GitHub repository locations are as follows: <br />
          <p
            style={{
              margin: '0.5rem 0',
              fontWeight: 400,
            }}
          >
            Frontend: https://github.com/AcmeIO/frontend<br/> 
            Product Catalog Service: https://github.com/AcmeIO/productcatalogservice<br/>
            Checkout service: https://github.com/AcmeIO/checkoutservice<br/>
          </p>
        </div>

        <div
          style={{
            fontWeight: 400,
          }}
        >
          Grafana URL: https://acme-demo-us.us-east4.gcp.acme.cloud/
        </div>

        <div
          style={{
            fontWeight: 400,
          }}
        >
          Prometheus datasource name: MetricsServer
        </div>

        <div
          style={{
            fontWeight: 400,
          }}
        >
          APM dashboard URL:
          https://acme-demo-us.acme.cloud/d/xvh2p-acme/apm-service-insights?orgId=1<br/>
          APM dashboard has details of application performance metrics like RPM,
          latency, error rates and list of slow queries
        </div>

        <div
          style={{
            fontWeight: 400,
          }}
        >
          <p
            style={{
              fontWeight: 400,
              margin: 0,
            }}
          >
            ArgoCD UI URL: https://deploynow-acme-demo.acme.cloud/ArgoCD<br/>
            App manifests are maintained at: https://github.com/AcmeIO/argo<br/>
            Each App has a folder by its name for its manifests.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Onboarding;
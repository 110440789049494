import React from 'react';
import { PulseLoader } from 'react-spinners';
import aiden from './aiden.png';

const LoadingDeck: React.FC = () => {
  return (
    <div
    style={{
      display: 'flex',
      width: '100%',
      marginTop: '1.2rem',
      gap: '0.75rem',
      maxWidth: '24rem',
      marginLeft: '0.5%',
    }}
    >
      <div
        style={{
          flexShrink: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '2.5rem',
          width: '2.5rem',
        }}
      >
        <div
          style={{ width: '2rem', height: '2.5rem' }}
        >
          <img src={aiden} alt="aiden icon" width={30} height={30} />
        </div>{' '}
      </div>
      <div>
        <div style={{ padding: '0.75rem', borderRadius: '0.125rem 0 0 1rem' }}>
          <PulseLoader
            color="#00a9a5"
            size={8}
            style={{ justifyContent: 'left', textAlign: 'left' }}
          />
        </div>
      </div>
    </div>
  );
};

export default LoadingDeck;
import React, { PropsWithChildren } from 'react';
import { Link, makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import { NavLink } from 'react-router-dom';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DeveloperBoard from '@material-ui/icons/DeveloperBoard';
import CloudUpload from '@material-ui/icons/CloudUpload';
import PlaylistAddCheckRounded from '@material-ui/icons/PlaylistAddCheckRounded';
import CategoryIcon from '@material-ui/icons/Category';
import { ObService } from '@internal/plugin-service/src/context/observenow/obapi.service';
import posthog from 'posthog-js';
import { setEnableLegacyAlerting } from '@internal/plugin-service/src/context/observenow/ob.actions';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import Cookies from 'js-cookie';

const setPostHogFeatureFlag = async () => {
    const url = `v1/users/info`;
    const response = await ObService.getUserDetails(url);
    if (response?.customerInfo?.name !== null) {
      posthog.identify(response?.customerInfo?.name, {
        customerName: response?.customerInfo?.name,
        email: response?.email,
      });
    }
  posthog.onFeatureFlags(function () {
    if (posthog.isFeatureEnabled('enableLegacyAlerting')) {
      setEnableLegacyAlerting(true);
    }
  });
}

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    margin: '0 auto',
    display:'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
});

const SidebarLogo = () => {
  const config = useApi(configApiRef);
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();
  const sidebarLogo = config.getString("customerCustomizations.sidebar")

  React.useEffect(() => {
    setPostHogFeatureFlag();
  }, []);

  return (
    <div className={classes.root}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={classes.link}
        aria-label="Home"
      >
        {isOpen ? sidebarLogo == "opsverse" ? <LogoFull /> :
        <div style={{padding: "25px", width: "225px"}}><img src={sidebarLogo} width={"100%"}></img></div> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const config = useApi(configApiRef);
  const enableObserveNow = config.getBoolean("customerCustomizations.observeNow.enable")
  const enableDeployNow = config.getBoolean("customerCustomizations.deployNow.enable")
  const enableFossa = config.getBoolean("customerCustomizations.plugins.fossa.enable")
  const staticToken = config.getOptionalString('customerCustomizations.staticToken');
  if (staticToken && staticToken.length > 0) {
    Cookies.set('opsverse-token', staticToken, {
      domain: `${window.location.hostname}`,
      expires: 30,
      sameSite: 'lax',
    });
    Cookies.set('oauth2-refresh-token', staticToken, {
      domain: `${window.location.hostname}`,
      expires: 30,
      sameSite: 'lax',
      path: '/api/auth/oauth2',
    });
  }
  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
          <SidebarSearchModal />
        </SidebarGroup>
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          {/* Global nav, not org-specific */}
          <SidebarItem icon={HomeIcon} to="/" text="Home" />
          <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog" />
          { enableObserveNow && <SidebarItem
            style={{ textDecoration: 'none' }}
            icon={DeveloperBoard}
            to="/service/observenow"
            text="ObserveNow"
          /> }
          { enableDeployNow && <SidebarItem
            style={{ textDecoration: 'none' }}
            icon={CloudUpload}
            to="/service/deploynow"
            text="DeployNow"
          /> }
          <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
          <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
          <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
          { enableFossa && <SidebarItem icon={PlaylistAddCheckRounded} to="fossa" text="FOSSA" /> }
          {/* End global nav */}
          <SidebarDivider />
          <SidebarScrollWrapper>
            <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
          </SidebarScrollWrapper>
        </SidebarGroup>
        <SidebarSpace />
        <SidebarDivider />
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <SidebarSettings />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  )
};

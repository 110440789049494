import Cookies from 'js-cookie';

const token = `Bearer ${Cookies.get('opsverse-token')}`;
let apiEndPoint: string;

const Fetcher = {
  headers: {
    Authorization: token,
    'Content-Type': 'application/json',
  },

  csr: async function csr(url: string, options: any = {}) {
    if (!apiEndPoint) {
      apiEndPoint = localStorage.getItem('apiEndPoint')!;
    }
    const res: any = await fetch(`${apiEndPoint}${url}`, {
      credentials: 'same-origin',
      ...options,
      headers: {
        ...Fetcher.headers,
        ...options?.headers,
      },
    });

    // eslint-disable-next-line consistent-return
    return res.json();
  },
};

export default Fetcher;

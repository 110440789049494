import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Edit from '@material-ui/icons/Edit';
import Notification from '@material-ui/icons/NotificationImportant';
import { AidenService } from '../../aidenContext/aidenapi.service';
import PageLoader from '@internal/plugin-service/src/components/atoms/pageLoader';
import TurnedInOutlinedIcon from '@material-ui/icons/TurnedInOutlined';
import { boxColor, primaryColor, secondaryColor, sideDrawerColor } from '../../../theme';

interface TrainAidenProps {
  flow: boolean;
  close: () => void;
  orgContext: string;
}

function TrainAiden({ flow, close, orgContext }: TrainAidenProps) {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data: { baseContext?: string }) => {
    setIsEditable(false);

    const response = await AidenService.saveContext('/context', {
      baseContext: data.baseContext ? data.baseContext : '',
      key: localStorage.getItem('token'),
    });

    <PageLoader />;
    if (response.error) {
      toast.error('Oops!, Something went wrong in updating the context');
      return;
    }

    close();
  };

  const handleButtonClick = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }

    setIsEditable(true);
    const textarea = document.getElementById('message') as HTMLTextAreaElement;
    textarea.focus();
  };

  useEffect(() => {
    if (flow) {
      handleButtonClick();
    }
  }, [flow]);

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        backgroundColor: sideDrawerColor,
      }}
    >
      <form
        style={{ display: 'flex', flexDirection: 'column', gap: 4 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <header
          style={{
            padding: 32,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            backgroundColor: primaryColor,
            height: 16,
            fontSize: 15,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              gap: 2,
              color: 'white',
            }}
          >
            This is what I know about your infrastructure so far.
          </div>
          {!isEditable ? (
            <button
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                border: 'none',
                cursor: 'pointer',
                padding: 8,
                borderRadius: 4,
                backgroundColor: secondaryColor,
                color: 'white',
                width: 80,
                justifyContent: 'center',
              }}
              onClick={e => handleButtonClick(e)}
            >
              EDIT <Edit style={{ width: 16, height: 16, cursor: 'pointer' }} />
            </button>
          ) : (
            <button
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                border: 0,
                textTransform: 'uppercase',
                backgroundColor: secondaryColor,
                color: 'white',
                padding: 8,
                borderRadius: 4,
                cursor: 'pointer',
                outline: 'none',
                width: 80,
                justifyContent: 'center',
              }}
              type="submit"
            >
              Save{' '}
              <TurnedInOutlinedIcon
                style={{ width: 16, height: 16, cursor: 'pointer' }}
              />
            </button>
          )}
        </header>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            paddingLeft: 12,
            paddingRight: 12,
          }}
        >
          <textarea
            defaultValue={orgContext}
            id="message"
            {...register('baseContext')}
            readOnly={!isEditable}
            style={{
              padding: 8,
              width: '100%',
              fontSize: '0.96rem',
              color: 'black',
              border: 'none',
              outline: 'none',
              borderRadius: 4,
              boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
              backgroundColor: isEditable ? 'white' : boxColor,
            }}
            rows={32}
            cols={40}
            placeholder="This is a free text field, so feel free to enter all the details you want to share with me so that I can understand your ops processes and infrastructure better."
          />
          {isEditable && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                fontSize: '0.875rem',
                fontWeight: 400,
                color: '#FF3333',
              }}
            >
              <Notification className="w-4 h-4 text-rossoCorsa-300" />
              Avoid sharing confidential and sensitive information like
              credentials or secrets.
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default TrainAiden;

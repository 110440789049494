import Cookies from 'js-cookie';

let aidenEndPoint: string;

const aidenFetcher = {
  csr: async function csr(url: string, options: any = {}) {
    if (!aidenEndPoint) {
      aidenEndPoint = localStorage.getItem('aidenEndPoint')!;
    }

    const token = `Bearer ${Cookies.get('opsverse-token')}`;

    const res: any = await fetch(`${aidenEndPoint}${url}`, {
      credentials: 'same-origin',
      ...options,
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        ...options?.headers,
      },
    });

    return res.json();
  },
};

export default aidenFetcher;

import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import UserDeck from './Deck/userDeck';
import LoadingDeck from './Deck/loadingDeck';
import Onboarding from './onboarding';
import SendRounded from '@material-ui/icons/SendRounded';
import ReplayIcon from '@material-ui/icons/Replay';
import BotDeck from './Deck/botDeck';
import { AidenService } from '../../aidenContext/aidenapi.service';
import {
  configApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { AppBar, Toolbar, Typography, Box, Tooltip } from '@material-ui/core';
import { Button } from '@material-ui/core';
import aiden from './Deck/aiden.png';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import SideDrawer from '@internal/plugin-service/src/components/atoms/sideDrawer';
import TrainAiden from './trainAiden';
import {
  boxColor,
  footerColor,
  primaryColor,
  secondaryColor,
  sideDrawerColor,
} from '../../../theme';

const ChatAi = ({}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [chatList, setChatList] = useState<ChatItem[]>([]);
  const [fetchingStarted, setFetchingStarted] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [editAidenData, setEditAidenData] = useState(false);
  const [flow, setFlow] = useState(false);
  const [orgContext, setOrgContext] = useState('');
  const [userName, setUserName] = useState('');
  const config = useApi(configApiRef);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
  } = useForm();

  const identityApi = useApi(identityApiRef);

  interface ChatItem {
    sender: "user" | "loading" | "opsbot";
    content: {
      response: string,
      conversationId?: string,
      interactionId?: string
    };
  }

  const getUserName = async () => {
    const staticToken = config.getOptionalString('customerCustomizations.staticToken');
    if (staticToken && staticToken.length > 0) {
      setUserName('Developer')
    } else {
      const userProfileInfo = await identityApi.getProfileInfo();
      setUserName(userProfileInfo?.displayName ?? '');
    }
  };

  const onSubmit: SubmitHandler<Record<string, string>> = async data => {
    if (data.userPrompt.trim() == "") return;
    reset();
    setFetchingStarted(true);
    let existingList = [...chatList];
    const newData: ChatItem[] = [
      {
        sender: 'user',
        content: {
          response: data.userPrompt,
        }
      },
      {
        sender: 'loading',
        content: {
          response: ''
        },
      },
    ];

    setChatList([...existingList, ...newData]);
    existingList = [...chatList, ...newData];
    ref.current?.lastElementChild?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });

    const payload = {
      query: data.userPrompt,
      conversationId: sessionStorage.getItem('conversationId'),
    };

    const dataResponse = await AidenService.askAiden('/ask', {
      data: payload,
    });

    if (dataResponse.error || dataResponse.errorCode) {
      dataResponse.response = 'Oops!, Something went wrong, Please try again!';
    }

    if (dataResponse.conversationId && dataResponse.interactionId) {
      sessionStorage.setItem('conversationId', dataResponse.conversationId);
      sessionStorage.setItem('interactionId', dataResponse.interactionId);
    }

    const newListData = existingList.filter(item => item.sender !== 'loading');

    setChatList([
      ...newListData,
      {
        sender: 'opsbot',
        content: dataResponse,
      },
    ]);
    ref.current?.lastElementChild?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    setFetchingStarted(false);
  };

  const handleGetStarted = (
    open: boolean | ((prevState: boolean) => boolean),
  ) => {
    setShowOnboarding(false);
    setEditAidenData(true);
    setFlow(open);
  };

  const checkContext = async () => {
    const response = await AidenService.checkAidenContext('/context');

    if (response.errorCode || response.error) {
      setShowOnboarding(true);
      return;
    }
    setOrgContext(response.baseContext);
    setShowOnboarding(false);
  };

  const aidenTextboxKeydown = (e: KeyboardEvent) => {
    if (e.key === "Enter" && e.shiftKey == false ) {
      e.preventDefault();
      if ((e.target as HTMLInputElement).value.trim() != "" ) {
        const data: { userPrompt: string } = { userPrompt: (e.target as HTMLInputElement).value };
        return handleSubmit(onSubmit(data) as SubmitHandler<FieldValues>);
      }
    }
    return;
  }

  useEffect(() => {
    const aidenEndPoint = config.getString('aidenUrl.aidenEndPoint');
    localStorage.setItem('aidenEndPoint', aidenEndPoint);
    checkContext();
    getUserName();
  }, [config]);

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100vh',
          overflow: 'hidden',
          backgroundColor: sideDrawerColor,
        }}
      >
        <AppBar
          position="sticky"
          style={{
            background: primaryColor,
            textAlign: 'left',
          }}
        >
          <Toolbar>
            <Typography style={{ flexGrow: 1, fontSize: '14px', fontWeight: 600 }}>
              <Box
                component="span"
                style={{
                  background: '#FFA500',
                  fontWeight: 400,
                  padding: '0.25rem 0.5rem',
                  borderRadius: '0.3125rem',
                  marginRight: '0.625rem',
                  color: 'white',
                }}
              >
                BETA
              </Box>
              Aiden - Your DevOps Copilot
            </Typography>
            {!showOnboarding && (
              <Button
                type="submit"
                onClick={() => setEditAidenData(true)}
                style={{
                  color: 'white',
                }}
              >
                <SettingsIcon />
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <div
          style={{
            background: sideDrawerColor,
            height: 'auto',
            minHeight: '85vh',
            maxHeight: '300%',
            textAlign: 'center',
          }}
        >
          {showOnboarding ? (
            <div
              style={{
                padding: '1rem',
              }}
            >
              <Onboarding />
              <footer
                style={{
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'left',
                  gap: '1rem',
                  margin: '1rem',
                  flexDirection: 'revert',
                }}
              >
                <Button
                  onClick={() => setShowOnboarding(false)}
                  variant="contained"
                  style={{
                    color: '#FF0000',
                    backgroundColor: 'white',
                    height: '2.25rem',
                    fontWeight: '500',
                    fontSize: '0.8rem',
                    textTransform: 'uppercase',
                    letterSpacing: '0.1rem',
                    borderRadius: '0.125rem',
                    border: 'none',
                    cursor: 'pointer',
                    outline: 'none',
                    padding: '0.5rem 1rem',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Skip for now
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => handleGetStarted(true)}
                  style={{
                    color: 'white',
                    backgroundColor: secondaryColor,
                    height: '2.25rem',
                    fontWeight: '500',
                    fontSize: '0.8rem',
                    textTransform: 'uppercase',
                    letterSpacing: '0.1em',
                    borderRadius: '0.125rem',
                    border: 'none',
                    cursor: 'pointer',
                    outline: 'none',
                    padding: '0.5rem 1rem',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Get Started
                </Button>
              </footer>
            </div>
          ) : (
            <>
              <div
                style={{
                  height: '76vh',
                  flex: '1',
                  overflowY: 'auto',
                  padding: '0.25rem',
                }}
                ref={ref}
              >
                {chatList.length !== 0 ? (
                  chatList?.map((chat, i) => (
                    <div key={i}>
                      {chat.sender === 'user' && (
                        <UserDeck
                          content={chat.content.response}
                          userName={userName}
                        />
                      )}
                      {chat.sender === 'loading' && <LoadingDeck />}
                      {chat.sender === 'opsbot' && <BotDeck chat={ chat } />}
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1.5rem',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      maxWidth: '45rem',
                      textAlign: 'center',
                      margin: '0 auto',
                    }}
                  >
                    <div>
                      <img
                        src={aiden}
                        alt="aiden icon"
                        width={60}
                        height={60}
                      />
                    </div>{' '}
                    <div>
                      <div style={{ textAlign: 'center' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                          Hi, {userName}!
                        </div>
                        <div
                          style={{
                            width: '70%',
                            margin: 'auto',
                            fontSize: '1rem',
                            paddingTop: '1.5rem',
                            paddingBottom: '1.5rem',
                          }}
                        >
                          I'm Aiden, your DevOps Copilot. I'm here to answer any
                          DevOps related questions you may have.
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '1rem',
                          marginBottom: '2%',
                        }}
                      >
                        <Box
                          style={{
                            padding: '0.5rem',
                            background: boxColor,
                            borderRadius: '0.25rem',
                            color: '#000',
                            width: '70%',
                            marginLeft: '15%',
                          }}
                        >
                          How can I check the error rate of my apps?
                        </Box>
                      </div>
                      <div
                        style={{
                          gap: '3',
                          marginBottom: '2%',
                          marginTop: '1%',
                          width: '70%',
                          marginLeft: '15%',
                        }}
                      >
                        <Box
                          style={{
                            padding: '0.5rem',
                            background: boxColor,
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem',
                            borderRadius: '0.5rem',
                            width: '100%',
                          }}
                        >
                          Define an alert for a k8s pod CPU usage
                        </Box>
                      </div>
                      <div
                        style={{
                          gap: '3',
                          marginBottom: '2%',
                          marginTop: '1%',
                          width: '70%',
                          marginLeft: '15%',
                        }}
                      >
                        <Box
                          style={{
                            padding: '0.5rem',
                            background: boxColor,
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem',
                            borderRadius: '0.5rem',
                            width: '100%',
                          }}
                        >
                          Could you please generate a Dockerfile for a Node JS
                          app?
                        </Box>
                      </div>
                      <div
                        style={{
                          gap: '3',
                          marginBottom: '2%',
                          marginTop: '1%',
                          width: '70%',
                          marginLeft: '15%',
                        }}
                      >
                        <Box
                          style={{
                            padding: '0.5rem',
                            background: boxColor,
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem',
                            borderRadius: '0.5rem',
                            width: '100%',
                          }}
                        >
                          My docker image has the vulnerability CVE-2021-21285.
                          How can I fix it?
                        </Box>
                      </div>
                      <div
                        style={{
                          gap: '3',
                          marginBottom: '1%',
                          marginTop: '1%',
                          width: '70%',
                          marginLeft: '15%',
                        }}
                      >
                        <Box
                          style={{
                            padding: '0.5rem',
                            background: boxColor,
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem',
                            borderRadius: '0.5rem',
                            width: '100%',
                          }}
                        >
                          Could you please generate a GitHub actions yaml to
                          build and push a container?
                        </Box>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {!fetchingStarted && (
                <footer
                  style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: footerColor,
                  }}
                >
                  {chatList && chatList.length > 1 && (
                    <Tooltip title="End Chat" placement="top">
                      <div
                        onClick={() => {
                          setChatList([]);
                          sessionStorage.removeItem('conversationId');
                        }}
                        style={{
                          color: 'white',
                          borderRadius: '0.3rem',
                          position: 'absolute',
                          top: '-2.5rem',
                          right: '1.2rem',
                          width: 'auto',
                          height: '2.5rem',
                          backgroundColor: '#5a5a5a',
                          margin: '0 auto',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '0.875rem',
                          gap: '0.25rem',
                          cursor: 'pointer',
                          minWidth: '2.5rem',
                          padding: '0.5rem',
                          letterSpacing: 'widest',
                          textTransform: 'uppercase',
                          border: '0',
                          fontWeight: 'medium',
                        }}
                      >
                        <ReplayIcon
                          style={{
                            width: '1.8rem',
                            height: '1.5rem',
                          }}
                        />
                      </div>
                    </Tooltip>
                  )}
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: '0.5rem',
                      paddingRight: '0.5rem',
                      backgroundColor: footerColor,
                    }}
                  >
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '2',
                          marginTop: '3',
                          marginBottom: '3',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: '1',
                            gap: '1',
                          }}
                        >
                          <textarea
                            style={{
                              outline: 'none',
                              border: '1px solid #dfdfdf',
                              whiteSpace: 'pre-wrap',
                              resize: 'none',
                              marginTop: '0.5rem',
                              width: '100%',
                              height: '4.5rem',
                              padding: '0.5rem',
                              borderRadius: '0.2rem',
                              fontFamily: 'sans-serif',
                            }}
                            rows={3}
                            placeholder="Talk to Aiden..."
                            autoComplete="off"
                            autoFocus
                            onKeyDown={ aidenTextboxKeydown }
                            {...register('userPrompt', { required: true })}
                          />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: 'text.secondary',
                              fontSize: '0.6rem',
                              marginTop: '0.3rem',
                              marginBottom: '0.3rem',
                              marginLeft: '15%',
                            }}
                          >
                            By using Aiden, you accept the terms of the&nbsp;
                            <a
                              href="https://opsverse.io/betaterms/"
                              target="_blank"
                            >
                              <u>OpsVerse Beta Program</u>
                            </a>
                          </div>
                        </div>
                        <Button
                          disabled={!isDirty && !isValid}
                          type="submit"
                          variant="contained"
                          style={{
                            color: 'white',
                            backgroundColor: secondaryColor,
                            border: '0',
                            fontWeight: 'medium',
                            borderRadius: '0.3rem',
                            letterSpacing: 'widest',
                            textTransform: 'uppercase',
                            padding: '0.5rem',
                            alignItems: 'center',
                            marginLeft: '0.5rem',
                            opacity: !isDirty && !isValid ? 0.5 : 1,
                            marginBottom: '1.5rem',
                          }}
                        >
                          Send
                          <SendRounded
                            style={{
                              width: '2rem',
                              height: '1.5rem',
                              fontSize: '0.5rem',
                            }}
                          />
                        </Button>
                      </div>
                    </form>
                  </div>
                </footer>
              )}
            </>
          )}
          <SideDrawer
            width="40vw"
            open={editAidenData}
            close={() => setEditAidenData(false)}
          >
            <TrainAiden
              flow={flow}
              close={() => setEditAidenData(false)}
              orgContext={orgContext}
            />
          </SideDrawer>
        </div>
      </div>
    </>
  );
};

export default ChatAi;

import Fetcher from '../../libs/fetcher';

const ObService = (() => {
  const getUserDetails = async (url: string) => {
    try {
      const response = await Fetcher.csr(url, {
        method: 'GET',
      });
      return response;
    } catch (err) {
      return null;
    }
  };

  const launchInstance = async (payload: object) => {
    try {
      const response = await Fetcher.csr('v1/apps', {
        method: 'POST',
        body: JSON.stringify(payload),
      });

      return response;
    } catch (err) {
      return null;
    }
  };

  const getAppStatus = async (url: string) => {
    try {
      const response = await Fetcher.csr(url, {
        method: 'GET',
      });
      return response;
    } catch (err) {
      return null;
    }
  };

  const verifyIngestionData = async (url: string) => {
    try {
      const response = await Fetcher.csr(url, {
        method: 'GET',
      });
      return response;
    } catch (err) {
      return null;
    }
  };

  const getApps = async () => {
    const isDemo = sessionStorage.getItem('isDemo');
    const customerName = isDemo === 'true' ? 'demo' : 'customer';

    try {
      const response = await Fetcher.csr(
        `v1/apps/${customerName}?appType=ObserveNow`,
        {
          method: 'GET',
        },
      );
      return response;
    } catch (err) {
      return null;
    }
  };

  const getClusters = async () => {
    try {
      const response = await Fetcher.csr('v1/clusters/customers/all', {
        method: 'GET',
      });
      return response;
    } catch (err) {
      return null;
    }
  };

  const checkApplicationStatus = async () => {
    try {
      const response = await Fetcher.csr(
        'v1/subscriptions/app-types/observability',
        {
          method: 'GET',
        },
      );
      return response;
    } catch (err) {
      return null;
    }
  };

  const getAlerts = async (url: string) => {
    try {
      const response = await Fetcher.csr(url, {
        method: 'GET',
      });
      return response;
    } catch (err) {
      return null;
    }
  };

  const updateAlert = async (url: string, data: object) => {
    try {
      const response = await Fetcher.csr(url, {
        method: 'PUT',
        body: JSON.stringify(data),
      });

      return response;
    } catch (err) {
      return null;
    }
  };

  const getIngestionDetails = async (url: string) => {
    try {
      const response = await Fetcher.csr(url, {
        method: 'GET',
      });
      return response;
    } catch (err) {
      return null;
    }
  };

  const fetchPlans = async (url: string) => {
    try {
      const response = await Fetcher.csr(url, {
        method: 'GET',
      });
      return response;
    } catch (err) {
      return null;
    }
  };

  const createSubscription = async (url: string, payload: object) => {
    try {
      const response = await Fetcher.csr(url, {
        method: 'POST',
        body: JSON.stringify(payload),
      });

      return response;
    } catch (err) {
      return null;
    }
  };

  return {
    getUserDetails,
    launchInstance,
    getAppStatus,
    getApps,
    getClusters,
    checkApplicationStatus,
    verifyIngestionData,
    getAlerts,
    updateAlert,
    getIngestionDetails,
    fetchPlans,
    createSubscription,
  };
})();

export { ObService };

import * as React from 'react';
import { ScaleLoader } from 'react-spinners';

const PageLoader = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
        background: 'rgba(0,0,0,0.4)',
      }}
    >
      <ScaleLoader color="#132C33" />
    </div>
  );
};

export default PageLoader;

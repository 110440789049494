import { toast } from 'react-toastify';
import { modifiedData } from '../common/transform';
import { ObService } from './obapi.service';

/// /////////////////////////////////////////////////////////////
// Helper Methods
/// /////////////////////////////////////////////////////////////
export const invokeError = (dispatch: any) => (message: string) => {
  dispatch({
    type: 'error',
    payload: message,
  });

  setTimeout(() => {
    dispatch({
      type: 'error',
      payload: undefined,
    });
  }, 2000);
};

export const loader = (dispatch: any) => (status: boolean) => {
  dispatch({
    type: 'page_loader',
    payload: status,
  });
};

export const handleStepChange = (dispatch: any) => (step: number) => {
  dispatch({ type: 'subscription_step', payload: step });
};

export const savePaymentDetails = (dispatch: any) => (details: object) => {
  dispatch({ type: 'subscription_payment_details', payload: details });
  handleStepChange(dispatch)(2);
};

export const handleSubscriptionReset = (dispatch: any) => () => {
  dispatch({ type: 'committed_data', payload: {} });
  dispatch({ type: 'show_data_commitment', payload: false });
  handleStepChange(dispatch)(0);
};

export const updateMinimumCommitment =
  (dispatch: any) => async (commitment: string) => {
    dispatch({ type: 'committed_data', payload: commitment });
    handleStepChange(dispatch)(1);
  };

export const handlePlanSelection =
  (dispatch: any) => async (plan: object, type: string) => {
    dispatch({ type: 'subscription_selected_plan', payload: plan });
    if (type === 'Annual') {
      dispatch({ type: 'show_data_commitment', payload: true });
      return;
    }
    handleStepChange(dispatch)(1);
  };

export const getSubscriptionPlans =
  (dispatch: any) => async (appType: string, appTypeId: string) => {
    loader(dispatch)(true);
    const url = `v1/app/categories/subscriptions/${appType}/${appTypeId}`;
    const response = await ObService.fetchPlans(url);

    if (response?.errorCode) {
      dispatch({ type: 'subscription_plans', payload: {} });
      return;
    }

    for (const prop in response) {
      if (response[prop].description !== null) {
        response[prop].description = response[prop].description.split(',');
      } else {
        response[prop].description = [];
      }
    }

    const annualList = response
      .reverse()
      .filter(
        (plan: any) => plan.metadata.Subscription === 'Annual' && plan.active,
      );

    const pgList = response.filter(
      (plan: any) =>
        plan.metadata.Subscription === 'Pay-as-you-go' && plan.active,
    );

    dispatch({ type: 'subscription_plans', payload: { annualList, pgList } });
    loader(dispatch)(false);
  };

export const handleIngestion = (dispatch: any) => async (url: any) => {
  dispatch({ type: 'ingestion_details', payload: {} });
  loader(dispatch)(true);
  const response = await ObService.getIngestionDetails(url);
  dispatch({ type: 'ingestion_details', payload: response });
  loader(dispatch)(false);
};

export const getAlerts =
  (dispatch: any) => async (instance: { name: string }) => {
    const url =
      sessionStorage.getItem('isDemo') === 'true'
        ? `v1/infra/app-instances/observability/${instance.name}/configs?isDemoAlert=true`
        : `v1/infra/app-instances/observability/${instance.name}/configs`;

    loader(dispatch)(true);
    const response = await ObService.getAlerts(url);

    loader(dispatch)(false);
    if (response?.errorCode === 'ERR_INFRA_003') {
      dispatch({ type: 'alerts_list', payload: undefined });
      return;
    }

    dispatch({ type: 'alerts_list', payload: response });
  };

export const updateAlert =
  (dispatch: any, state: any) => async (data: object, instanceName: string) => {
    loader(dispatch)(true);
    const url = `v1/infra/app-instances/observability/${instanceName}/configs`;
    const response = await ObService.updateAlert(url, data);
    if (response?.errorCode) {
      loader(dispatch)(false);
      toast.error(`Oops!, Something went wrong in updating config`);
      return;
    }

    const newData = state.alertsList;
    for (const prop in newData) {
      if (
        newData[prop].file_basename === response.file_basename &&
        newData[prop].component_name === response.component_name
      ) {
        newData[prop] = response;
      }
    }
    loader(dispatch)(false);
    dispatch({ type: 'alerts_list', payload: newData });
    toast.success(`configurations updated for ${instanceName} instance`);
  };

export const handleLaunchFlow =
  (dispatch: any, state: any) => (status: boolean) => {
    dispatch({ type: 'show_launch_flow', payload: status });
    dispatch({ type: 'launch_step', payload: 0 });
    if (state.launchStep === 1) {
      window.location.reload();
    }
  };

export const handleSubscriptionFlow = (dispatch: any) => (status: boolean) => {
  dispatch({ type: 'show_subscription_flow', payload: status });
  dispatch({ type: 'subscription_step', payload: 0 });
};

export const verifyInstanceStatus = (dispatch: any, state: any) => async () => {
  loader(dispatch)(true);
  const url = `v1/apps/customer/observability/${state.pendingApp.name}/status`;
  const response = await ObService.getAppStatus(url);
  if (response?.errorCode) {
    loader(dispatch)(false);
    toast.error(
      `Oops!, Something went wrong in retrieving the status for ${state.pendingApp?.name}`,
    );
    return;
  }
  loader(dispatch)(false);
  if (response.status === 'active') {
    toast.success(
      ` ${state.pendingApp?.name} stack created successfully, Please complete the agent integration`,
    );
    dispatch({ type: 'launch_step', payload: 2 });
    setTimeout(() => {
      window.location.reload();
    }, 2000);

    return;
  }
  toast.warning(
    `${state.pendingApp?.name} stack creation is in progress, please try again later`,
  );
};

export const checkAgentStatus =
  (dispatch: any, state: any) => async (url: string, instance: any) => {
    loader(dispatch)(true);
    const response = await ObService.verifyIngestionData(url);
    loader(dispatch)(false);

    if (response?.errorCode || !response) {
      dispatch({ type: 'agent_verification_error', payload: true });
      return;
    }
    dispatch({ type: 'agent_verification_error', payload: false });
    dispatch({ type: 'pending_app', payload: undefined });

    const existingData = instance;
    existingData.agentstatus = 'active';
    existingData.status = 'active';

    const newData = state.appsList;
    for (const prop in newData) {
      if (newData[prop].id === existingData.id) {
        newData[prop] = existingData;
      }
    }

    dispatch({ type: 'apps_list', payload: newData });
    dispatch({ type: 'enable_launch', payload: true });
    toast.success(`${instance?.name} instance is ready for use!`);
  };

export const createInstance = (dispatch: any) => async (payload: object) => {
  loader(dispatch)(true);
  const response = await ObService.launchInstance(payload);
  if (response?.errorCode) {
    loader(dispatch)(false);
    toast.error(`Oops!, Something went wrong try again later!`);
    return;
  }
  dispatch({ type: 'pending_app', payload: response });
  dispatch({ type: 'launch_step', payload: 1 });
  loader(dispatch)(false);
};

export const getClusters = (dispatch: any) => async () => {
  const response = await ObService.getClusters();
  if (response.errorCode) {
    invokeError(dispatch)(response.message);
    return;
  }
  dispatch({ type: 'cluster_list', payload: response });
};

export const getApplicationStatus = (dispatch: any) => async () => {
  dispatch({ type: 'apps_list', payload: [] });

  const response = await ObService.checkApplicationStatus();
  if (response.errorCode === 'ERR_SUBS_001') {
    dispatch({
      type: 'app_subscription_status',
      payload: {
        subscriptionType: 'trial',
      },
    });
    return;
  }
  dispatch({ type: 'app_subscription_status', payload: response });
};

export const handleSelectedInstance =
  (dispatch: any) => async (instance: object) => {
    loader(dispatch)(true);
    dispatch({ type: 'selected_instance', payload: undefined });
    dispatch({ type: 'alerts_list', payload: undefined });

    setTimeout(() => {
      loader(dispatch)(false);
      dispatch({ type: 'selected_instance', payload: instance });
    }, 500);
  };

export const getAppsList = (dispatch: any) => async () => {
  const response = await ObService.getApps();
  loader(dispatch)(false);
  if (response.errorCode === 'ERR_APPS_001') {
    dispatch({
      type: 'start_trial',
      payload: true,
    });
    return;
  }

  const result = modifiedData(response);
  const enableLaunch = result.every(
    (item: any) => item.status === 'active' && item.agentstatus === 'active',
  );
  if (!enableLaunch) {
    for (const prop in result) {
      if (result[prop].status === 'pending') {
        dispatch({ type: 'launch_step', payload: 1 });
        break;
      } else if (result[prop].agentstatus === 'pending') {
        dispatch({ type: 'launch_step', payload: 2 });
        break;
      }
    }
    for (const prop in result) {
      if (
        result[prop].status === 'pending' ||
        result[prop].agentstatus === 'pending'
      ) {
        dispatch({ type: 'pending_app', payload: result[prop] });
        break;
      }
    }
  }
  if (result.length > 0) {
    handleSelectedInstance(dispatch)(result[0]);
  }

  dispatch({ type: 'enable_launch', payload: enableLaunch });
  dispatch({
    type: 'update_apps_list',
    payload: result,
  });
};

export const getUserInfo = (dispatch: any) => async () => {
  const url = `v1/users/info`;
  const response = await ObService.getUserDetails(url);
  if (response.errorCode) {
    toast.error('Something went wrong in fetching user details');
    return;
  }
  dispatch({ type: 'update_user_info', payload: response });
};

export const init = (dispatch: any) => async () => {
  loader(dispatch)(true);
  await getUserInfo(dispatch)();
  await getClusters(dispatch)();
  await getApplicationStatus(dispatch)();
  await getAppsList(dispatch)();
};

export const createSubscription =
  (dispatch: any, state: any) => async (payload: object) => {
    loader(dispatch)(true);
    const url = `v1/app/categories/subscriptions`;
    const response = await ObService.createSubscription(url, payload);
    loader(dispatch)(false);
    if (response?.errorCode) {
      dispatch({ type: 'subscription_plans', payload: {} });
      toast.error(response.message);
      return;
    }
    toast.success(
      `Congrajulations! You have successfully subscribed to ${state.subscriptionSelectedPlan.name}`,
    );
    dispatch({ type: 'show_subscription_flow', payload: false });
    init(dispatch)();
  };

let enableLegacyAlerting = false;

const setEnableLegacyAlerting = (payload: boolean) => {
  enableLegacyAlerting = payload;
};

export { enableLegacyAlerting, setEnableLegacyAlerting };

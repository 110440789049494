import { Box } from '@material-ui/core';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

interface UserDeckProps {
  content: string;
  userName: string;
}

const UserDeck: React.FC<UserDeckProps> = ({ content, userName }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        marginTop: '2rem',
        marginBottom: '2rem',
        gap: '0.75rem',
        maxWidth: '90%',
        marginLeft: 'auto',
        justifyContent: 'flex-end',
      }}
      className="userStyles"
    >
      <div>
        <div
          style={{
            backgroundColor: '#dfdfdf',
            color: 'black',
            padding: '0.5rem',
            borderRadius: '0.125rem',
            boxShadow:
              '0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <div
            style={{
              whiteSpace: 'pre-wrap',
              fontWeight: 'regular',
              textAlign: 'left',
            }}
          >
            {ReactHtmlParser(content)}
          </div>
        </div>
      </div>
      <div
        style={{
          flexShrink: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '2rem',
          height: '2rem',
          borderRadius: '50%',
          fontSize: '0.875rem',
          fontWeight: 'regular',
          textTransform: 'capitalize',
          marginTop: '0.125rem',
          marginRight: '0.8rem'
        }}
      >
        <Box
          style={{
            width: '30px',
            height: '30px',
            background: '#132C33',
            borderRadius: '50%',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p style={{ margin: '0' }}>{userName[0]}</p>
        </Box>
      </div>
    </div>
  );
};

export default UserDeck;

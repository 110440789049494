import aidenFetcher from "../libs/aidenFetcher";

interface AskAidenPayload {
  data: {
      query: string;
      conversationId: string | null;
  }
}

interface AidenSaveContextPayload {
      baseContext: string;
      key: string | null;
}

interface AidenFeedbackPayload {
  data: {
    interactionId: string,
    conversationId: string,
    reaction: string,
  };
}

const AidenService = (() => {
  const askAiden = async (url: string, payload: AskAidenPayload) => {
    try {
      const response = await aidenFetcher.csr(url, {
        method: 'POST',
        body: JSON.stringify(payload.data),
      });

      return response;
    } catch (err) {
      return null;
    }
  };

  const checkAidenContext = async (url: string) => {
    try {
      const response = await aidenFetcher.csr(url, {
        method: 'GET',
      });

      return response;
    } catch (err) {
      return null;
    }
  };

  const saveContext = async (url: string, payload: AidenSaveContextPayload) => {
    try {
      const response = await aidenFetcher.csr(url, {
        method: 'POST',
        body: JSON.stringify(payload),
      });

      return response;
    } catch (err) {
      return null;
    }
  };

  const feedback = async (url: string, payload: AidenFeedbackPayload) => {
    try {
      const response = await aidenFetcher.csr(url, {
        method: 'POST',
        body: JSON.stringify(payload.data),
      });

      return response;
    } catch (err) {
      return null;
    }
  };

  return {
    askAiden,
    checkAidenContext,
    saveContext,
    feedback,
  };
})();

export { AidenService };
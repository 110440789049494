import React from 'react';
import { Drawer } from '@material-ui/core';

type SideDrawerProps = {
  heading?: any;
  children: any;
  close: any;
  open: boolean;
  width?: string;
};

export default function SideDrawer({
  heading,
  children,
  close,
  open,
  width = '60vw',
}: SideDrawerProps) {

  return (
    
    <Drawer open={open} anchor="right" onClose={close}>
      <div
        style={{
          width: width,
        }}
      >
        {heading}
        {children}
      </div>
    </Drawer>
  );
}
